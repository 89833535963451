import React from "react";
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./src/utils/endpoint";
import { LanguageProvider } from "./src/utils/contexts/languageContext";

export const wrapPageElement = ({ element, props }) => (
  <QueryClientProvider client={queryClient}>
    <LanguageProvider>{element}</LanguageProvider>
  </QueryClientProvider>
);
