import React, {
  createContext,
  ReactNode,
  ReactElement,
  useContext,
} from "react";
import useSessionStorage from "../hooks/sessionStorage";

export interface ILanguageState {
  languageCode: string;
}

export const initialState: ILanguageState = { languageCode: "" };

const LanguageContext = createContext<ILanguageState>(initialState);
const LanguageDispatchContext = createContext<
  ((value: ILanguageState) => void) | undefined
>(undefined);

export const LanguageProvider = ({
  children,
  initial,
}: {
  children?: ReactNode;
  initial?: ILanguageState;
}): ReactElement => {
  const [languageState, setLanguageState] = useSessionStorage(
    "lang",
    initial ?? initialState
  );

  return (
    <LanguageContext.Provider value={languageState}>
      <LanguageDispatchContext.Provider value={setLanguageState}>
        {children}
      </LanguageDispatchContext.Provider>
    </LanguageContext.Provider>
  );
};

/**
 * Returns language state.
 */
export const useLanguageState = (): ILanguageState => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguageState must be used within a LanguageProvider");
  }
  return context;
};

/**
 * Returns ready to use dispatch method to change language state.
 */
export const useLanguageDispatch = (): ((value: ILanguageState) => void) => {
  const context = useContext(LanguageDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLanguageDispatch must be used within a LanguageProvider"
    );
  }
  return context;
};
