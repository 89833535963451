import axios from "axios";
import { QueryClient } from "react-query";

const baseURL = process.env.GATSBY_API_ENDPOINT;

export const axiosInstance = axios.create({
  baseURL,
});

// Disable automatic refetch by default to prevent unwanted calls to directus in build time.
export const queryClient = new QueryClient();
